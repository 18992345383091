import { SummaryVisibility } from "~/composables/enums/SummaryVisibility";
import type {SummaryDraftRequestDto} from "~/entities/Summary/SummaryDraftRequestDto";

export type SummaryRecords = {
    id: string|null,
    question: string,
    hash: string|null,
};
export type UpdateSummariesBody = {
    professions?: number[],
    dateBirth?: string,
    experienceInProfession?: number,
    experience?: number,
    schedules?: number[],
    records?: SummaryRecords[],
    visibility?: SummaryVisibility,
    status?: number,
};
type _Get = {
    profession: boolean| null,
    city: boolean| null
};
type GetSummariesQuery = {
    client?: string,
    profession?: number,
    city?: number,
    page?: number,
    get?: _Get
};

export const useApiSummaries = () => {
    return {
        add: async (body: SummaryDraftRequestDto) => {
            return await mainFetch('summaries', {
                method: 'POST',
                body
            }, true);
        },
        get: async (query: GetSummariesQuery = {} as GetSummariesQuery) => {
            return await mainFetch('summaries', {
                method: 'GET',
                query
            });
        },
        getOne: async (id: string) => {
            return await mainFetch(`summaries/${id}`, {
                method: 'GET'
            });
        },
        update: async (id: string, body: UpdateSummariesBody) => {
            return await mainFetch(`summaries/${id}`, {
                method: 'PUT',
                body
            }, true);
        },
        delete: async (id: string) => {
            return await mainFetch(`summaries/${id}`, {
                method: 'DELETE'
            });
        }
    };
}
